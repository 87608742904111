// General
// -----------------------------------------------

* {
  -webkit-font-smoothing: antialiased !important;
  font-synthesis: none !important;
  @apply focus:outline-none;
}

html,
body {
  height: 100%;
  width: 100%;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: initial;
}

hr {
  @apply text-gray-light;
}

// Page Content
// -----------------------------------------------
.content-block {
  @apply max-w-screen-xl m-auto px-4;
}

.max-content-width {
  @apply max-w-[1500px];
}

// Page Title
// -----------------------------------------------
.main-page-title-area {
  @apply mb-12;
}

.main-page-title {
  @apply text-4xl;
}

.main-page-subtitle {
  @apply text-2xl;
}

// Custom Label
// -----------------------------------------------
.custom-label {
  @apply rounded-full inline-flex leading-none text-light font-redHatTextSemibold px-[0.4rem] py-1 self-end text-[11px] items-center gap-1;

  &--accent-light {
    /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
    @extend .custom-label;
    @apply bg-periwinkle-light text-accent;
  }

  &--cornsilk {
    @extend .custom-label;
    @apply bg-cornsilk text-dark;
  }
}

// Sections
// -----------------------------------------------

.section-title {
  @apply mb-8 text-xl flex items-center gap-x-2;
}

// Input
// -----------------------------------------------
.custom-input {
  @apply max-w-[400px];

  &__label {
    @apply text-xs font-redHatTextSemibold inline-block;
  }

  &__label-optional-tag {
    @apply bg-gray-ultralight font-redHatTextSemibold text-xs px-1 py-0.5 text-gray-dark rounded-full ml-1;
  }

  &__description {
    @apply text-xs text-gray-dark h-8 my-[3px];
  }

  mat-hint {
    @apply text-gray-dark flex gap-0.5 items-center;

    &.warning {
      @apply text-warning;
    }
  }
}

.custom-headline-input {
  .mat-mdc-input-element {
    @apply font-redHatTextSemibold;
    @apply text-4xl;
  }

  &__hint {
    &--accent {
      @apply text-accent;
    }
  }
}

mark {
  margin: 0 -0.4em;
  padding: 0.1em 0.4em;
  border-radius: 0.8em;
  background: transparent;
  background-image: linear-gradient(
    to right,
    rgb(235 235 254 / 30%),
    rgb(235 235 255 / 100%) 2%,
    rgb(235 235 255 / 50%)
  );
  box-decoration-break: clone;
}
