// CDK Dialog
// -----------------------------------------------
.cdk-dialog {
  @apply rounded-3xl py-8 bg-light justify-between flex flex-col gap-y-8;

  max-height: 95vh;
  max-width: 95vw;

  &--fill {
    @apply rounded-none w-full h-full max-w-full max-h-full;
  }

  .cdk-dialog-header {
    @apply px-8;

    &--back {
      @apply flex items-center gap-2.5;
    }

    &__title {
      @apply mb-0 font-redHatDisplayBold text-2xl;
    }

    &__back-button-icon {
      @apply transform scale-125;
    }
  }

  .cdk-dialog-body {
    @apply overflow-y-auto h-[100%] px-10;

    &__two-col-input-form {
      @apply grid gap-8;

      grid-template-columns: repeat(2, minmax(0, 400px));
    }
  }

  .cdk-dialog-body--separated {
    @apply border-y border-gray-ultralight p-10;
  }

  .cdk-dialog-actions {
    @apply flex justify-end gap-2 px-10;
  }
}

.cdk-overlay-pane.select-users-dialog {
  @apply w-[75%] h-[80%];
}

.cdk-overlay-pane.edit-employer-dialog {
  @apply h-full;
}

// CDK Table
// -----------------------------------------------
.cdk-table {
  @apply text-sm w-full text-left border-separate border-spacing-y-[0.75rem] table-fixed;

  &.clickable {
    .cdk-row {
      @apply cursor-pointer;

      &:hover .cdk-cell {
        @apply bg-opacity-50;
        @extend %transition-animation;
      }
    }
  }

  .cdk-header-row {
    @apply font-redHatTextSemibold h-12;
  }

  .cdk-header-cell {
    @apply px-6 py-2 truncate;
  }

  .cdk-row {
    @apply h-14;
  }

  .cdk-cell {
    @apply bg-light px-6 py-2 truncate;

    &:first-child {
      @apply rounded-tl-lg rounded-bl-lg;
    }

    &:last-child {
      @apply rounded-tr-lg rounded-br-lg;
    }
  }
}

// CDK Menu
// -----------------------------------------------
.cdk-menu {
  @apply bg-light shadow-xl rounded-md mt-3;
}
