#kompassify-progress-player {
  z-index: 10 !important;
}

.kompassify-progress-player-frame {
  opacity: 0;
  pointer-events: none;
}

.kompassify-progress-player-frame.visible {
  opacity: 1;
  pointer-events: initial;
}
