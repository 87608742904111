/* stylelint-disable scss/at-extend-no-missing-placeholder */
/* stylelint-disable selector-pseudo-element-no-unknown */

.settings-input-block {
  @apply flex mt-8 flex-col gap-9;
}

.settings-input-block-separator {
  @apply my-10;
}

.settings-input-row {
  @apply flex gap-6 items-center;

  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: -22px;
  }

  &__label-wrap {
    @apply flex gap-2 w-[17rem] items-center;
  }

  &__label {
    @apply font-redHatTextSemibold text-base flex-shrink-0;
  }

  &__optional {
    @extend .custom-input__label-optional-tag;
  }

  &__input-wrap {
    @apply max-w-[20rem] w-full h-10 flex items-center;
  }

  &__input-value {
    @apply px-4 truncate;
  }

  &__input-value--grey {
    @apply text-gray;
  }

  &__input {
    letter-spacing: normal !important;
  }
}
