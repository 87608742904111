mat-toolbar {
  &.banner {
    @apply flex-grow-0 px-6 py-4 flex justify-between;
  }

  .banner {
    &__content {
      @apply flex items-center text-base gap-x-1;
    }

    &__cta {
      @apply text-sm;
    }
  }
}
