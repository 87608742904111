// Animation
// -----------------------------------------------
%transition-animation {
  @apply transition duration-500  ease-in-out transform;
}

.transition-animation {
  @extend %transition-animation;
}

.btn-scale {
  @extend %transition-animation;
  @apply hover:scale-105;
}

.skeleton-loading {
  @apply bg-gray-light animate-pulse;
}
