@import '/src/styles/cards';

.statistics-filter-card-wrap {
  @apply h-full relative;

  &.disabled {
    @apply cursor-pointer;

    .statistics-filter-card {
      @apply pointer-events-none select-none;
    }

    .statistics-filter-card__disabled-overlay {
      @apply absolute w-full h-full bg-light opacity-50 top-0 left-0 block;
    }
  }
}

.statistics-filter-card {
  @extend .shadow-card;
  @apply w-[300px] cursor-pointer flex items-center gap-x-2 text-sm h-full;

  &__text {
    @apply font-redHatTextRegular truncate flex-grow;
  }

  &__disabled-overlay {
    @apply hidden;
  }
}
