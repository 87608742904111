@import 'mat-themes';
@import 'mat-overwrites';
@import 'cdk-mat-overwrites';
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'fonts';
@import 'base';
@import 'google-places-overwrites';
@import 'animation';
@import 'variables';
@import 'settings';
@import 'ngx-cropper-overwrites';
@import 'cards';
@import 'chips';
@import 'banner';
@import 'statistics-filter-card';
@import 'cookie-script';
@import 'ngx-toastr/toastr';
@import 'ngx-toastr-overwrites';
@import '~quill/dist/quill.bubble.css';
@import 'quill-editor-overwrites';
@import 'animate.css';
@import 'kompassify';
@import 'switch';
