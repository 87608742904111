// Hint: This file is used for changing global default material components

// Sidenav
// -----------------------------------------------
mat-sidenav .mat-drawer-inner-container::-webkit-scrollbar {
  display: none;
}

// Buttons
// -----------------------------------------------

button[mat-icon-button] {
  @apply leading-none;
}

%custom-material-button {
  @apply rounded-full font-redHatTextSemibold tracking-normal text-base  overflow-hidden;

  height: theme('height.11') !important;
  padding-left: theme('spacing.5') !important;
  padding-right: theme('spacing.5') !important;
}

button[mat-stroked-button],
button[mat-flat-button],
button[mat-button] {
  @extend %custom-material-button;
}

// TODO: revisit if still needed
// temporary fix for: https://github.com/angular/components/issues/26056
button[mat-flat-button][color='primary'] {
  @apply text-light;
}

button[mat-flat-button][color='primary'].inverted {
  @apply text-primary;

  --mdc-filled-button-container-color: theme('colors.light-cyan');
}

button[mat-button][disabled='true'] {
  @apply text-gray-dark;
}

button[mat-stroked-button]:not([disabled]) {
  border-color: theme('borderColor.primary');
}

.small-button {
  @apply transform scale-90;
}

//  Table
// -----------------------------------------------
%custom-material-table {
  @apply w-full bg-transparent bg-opacity-0 border-separate;

  border-spacing: 0 0.75rem;
}

.mat-mdc-table.custom-table {
  @extend %custom-material-table;
}

%custom-mat-header-row {
  @apply h-12;
}

.mat-mdc-table.custom-table .mat-mdc-header-row {
  @extend %custom-mat-header-row;
}

%custom-mat-row {
  @extend %transition-animation;
  @apply h-14 transform my-3;
}

.mat-mdc-table.custom-table .mat-mdc-row {
  @extend %custom-mat-row;
}

%custom-material-header-cell {
  @apply text-dark border-b-0 text-sm font-redHatTextSemibold px-6 bg-gray-light;
}

.mat-mdc-table.custom-table .mat-mdc-header-row .mat-mdc-header-cell {
  @extend %custom-material-header-cell;
}

.mat-mdc-table.custom-table--clickable-rows .mat-mdc-row {
  @apply cursor-pointer rounded-lg overflow-hidden;
}

.mat-mdc-table.custom-table--clickable-rows .mat-mdc-row:hover {
  // adds a box shadow hover effect on table rows
  @apply shadow-xl;
}

%custom-mat-cell {
  @apply border-b-0 text-sm px-6  bg-light py-2;
}

.mat-mdc-table.custom-table .mat-mdc-row .mat-mdc-cell {
  @extend %custom-mat-cell;

  * {
    @apply truncate;
  }
}

%custom-first-mat-cell {
  @apply rounded-tl-lg rounded-bl-lg;
}

.mat-mdc-table.custom-table .mat-mdc-row .mat-mdc-cell:first-child {
  @extend %custom-first-mat-cell;
}

%custom-last-mat-cell {
  @apply rounded-tr-lg rounded-br-lg;
}

.mat-mdc-table.custom-table .mat-mdc-row .mat-mdc-cell:last-child {
  @extend %custom-last-mat-cell;
}

.mat-mdc-table.custom-table .mat-sort-header-content {
  @apply text-left;
  @apply w-min;
}

// Expandable table
// -----------------------------------------------
table.mat-mdc-table.custom-table.expandable {
  width: 100%;
  border-spacing: 0;
}

table.mat-mdc-table.custom-table.expandable .detail-row {
  height: 0;
  transform: none !important;
}

table.mat-mdc-table.custom-table.expandable .detail-row .mat-mdc-cell {
  background: transparent;
  padding: 0;
}

table.mat-mdc-table.custom-table.expandable .spacer {
  height: 0.75rem;

  .mat-mdc-cell {
    background: transparent;
  }
}

// Menu
// -----------------------------------------------

.mat-mdc-menu-panel {
  box-shadow: theme('boxShadow.xl') !important;

  .mat-mdc-menu-content {
    padding: theme('padding.0') !important;
    @apply py-0 overflow-x-auto overflow-y-auto;
  }

  .mat-mdc-menu-item {
    @apply flex items-center gap-2;
  }

  .mat-mdc-menu-item-text {
    font-size: theme('fontSize.sm') !important;
  }

  .mat-icon {
    @apply transform scale-75;

    margin-right: 0 !important;
  }
}

.custom-mat-menu--destination-filter + * .cdk-overlay-pane,
.custom-mat-menu--with-spacing + * .cdk-overlay-pane {
  margin-top: 1rem;

  .mat-mdc-menu-panel {
    @apply rounded-md;

    border-top-left-radius: theme('borderRadius.md') !important;
    border-top-right-radius: theme('borderRadius.md') !important;
  }
}

.custom-mat-menu--destination-filter + * .mat-mdc-menu-content {
  max-height: 300px;

  .mat-checkbox-layout {
    @apply whitespace-normal;
  }
}

// Checkbox
// -----------------------------------------------

// TODO: revisit if still needed
// temporary fix for: https://github.com/angular/components/issues/26056

mat-checkbox[color='primary'] {
  .mdc-checkbox__checkmark {
    color: theme('colors.light.DEFAULT') !important;
  }
}

mat-checkbox.invalid {
  .mdc-checkbox__background {
    border-color: var(--mat-warn-color) !important;
  }
}

// Tabs
// -----------------------------------------------

mat-tab-group .mdc-tab__text-label {
  @apply font-redHatTextSemibold p-10 tracking-normal text-base;

  color: theme('colors.dark') !important;
}

mat-tab-group .mdc-tab {
  @apply min-w-[250px];
}

mat-tab-group .mat-mdc-tab-list {
  @apply border-b-gray border-b-[2px];
}

mat-tab-group .mat-mdc-tab:not(.mdc-tab--stacked) {
  @apply h-16;
}

mat-tab-group .mdc-tab-indicator__content--underline {
  @apply relative top-0.5;
}

mat-tab-group.no-header {
  mat-tab-header {
    @apply hidden;
  }
}

mat-tab-group .mat-mdc-tab-body-content {
  overflow: visible;
}

// Material Dialogs
// -----------------------------------------------

.dialog-overwrites {
  .mdc-dialog__container .mat-mdc-dialog-surface {
    @apply rounded-3xl px-10 py-10;
  }

  .mat-mdc-dialog-title {
    @apply font-redHatTextSemibold text-2xl;
  }
}

.select-users-dialog {
  /* stylelint-disable-next-line scss/at-extend-no-missing-placeholder */
  @extend .dialog-overwrites;
  @apply w-full h-full;
}

// Input
// -----------------------------------------------

mat-form-field {
  @apply w-full;
}

mat-form-field.hide-bottom-padding {
  // hides the bottom padding usually reserves space for mat-(error)-hints
  .mat-mdc-form-field-subscript-wrapper {
    @apply hidden;
  }
}

// Stepper
// -----------------------------------------------
mat-stepper.customer-stepper {
  .mat-horizontal-stepper-header-container {
    @apply mb-10;
  }

  .mat-step-icon {
    display: none !important;
  }

  .mat-stepper-horizontal-line {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
    border-top-color: var(--gray-color);
    border-top-width: 2px !important;
  }

  .mat-step-header-ripple:hover {
    border-radius: 20px !important;
  }

  .mat-step-label-selected {
    color: var(--primary-color) !important;
  }

  .mat-step-text-label {
    font-size: medium;
    @apply font-redHatTextSemibold;
  }

  .mat-horizontal-stepper-header {
    pointer-events: none !important;
  }
}

// Paginator
// -----------------------------------------------

mat-paginator {
  background: none !important;
}

// Icons
// -----------------------------------------------
mat-icon.mat-mdc-tooltip-trigger {
  @apply cursor-pointer;
}

// Chips
// -----------------------------------------------
mat-chip-row.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @apply bg-gray-light;
}
