/* stylelint-disable selector-id-pattern */

// cookie notice popup
#cookiescript_injected {
  bottom: 40px !important;
  right: 40px !important;
  top: initial !important;
  font-family: 'RedHatText_Regular', Montserrat, sans-serif !important;
  background-color: #000 !important;
  border-radius: 15px !important;
}

body.local #cookiescript_injected {
  display: none !important;
}

#cookiescript_header::before {
  content: url('/assets/images/icons/cookie.svg');
  display: block;
  width: 45px;
  fill: white;
  color: white;
  margin-bottom: 10px;
}

#cookiescript_header {
  padding: 14px 0;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

#cookiescript_accept {
  background-color: #00a89c !important;
  font-family: 'RedHatText_SemiBold', Montserrat, sans-serif !important;
  border-radius: 50px !important;
  transition: none !important;
}

#cookiescript_reject {
  background-color: transparent !important;
  font-family: 'RedHatText_SemiBold', Montserrat, sans-serif !important;
  border-radius: 50px !important;
  transition: none !important;
}

#cookiescript_description {
  opacity: 1 !important;
  letter-spacing: normal !important;
  margin-bottom: 16px !important;
}

#cookiescript_manage_wrap {
  margin: 8px 0 15px !important;
  font-family: 'RedHatText_SemiBold', Montserrat, sans-serif !important;
}

#cookiescript_readmore {
  text-decoration: underline !important;
  font-family: 'RedHatText_SemiBold', Montserrat, sans-serif !important;
  opacity: 0.9 !important;
}

#cookiescript_readmore:hover {
  opacity: 1 !important;
  color: white !important;
}

#cookiescript_close {
  display: none !important;
}

#cookiescript_manage {
  opacity: 0.9 !important;
}

#cookiescript_manage:hover {
  opacity: 1 !important;
}

#cookiescript_manage:hover #cookiescript_manageicon .cookiescript_gear {
  fill: white !important;
}

@media only screen and (width <= 414px) {
  #cookiescript_injected {
    left: 20px !important;
    bottom: 20px !important;
    width: calc(100% - 40px) !important;
  }
}

// cookie management

#cookiescript_fsd_wrapper {
  font-family: 'RedHatText_Regular', Montserrat, sans-serif !important;
}

#cookiescript_injected_fsd #cookiescript_accept {
  border: none !important;
  color: white !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_header {
  border-bottom: 1px solid #eceef4 !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_title {
  font-family: 'RedHatText_SemiBold', Montserrat, sans-serif !important;
}

#cookiescript_fsd_wrapper #cookiescript_readmore {
  color: #00a89c !important;
  font-family: 'RedHatText_SemiBold', Montserrat, sans-serif !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_tabs > .cookiescript_active {
  border-top: none !important;
  background-color: white !important;
  border-bottom: 1px solid #008a9c !important;
  color: #000 !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_tabs > div {
  transition: initial !important;
  border-bottom: 1px solid #eceef4 !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_cookies_control {
  color: #00a89c !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_tabs_content {
  background-color: white !important;
  mask-image: none !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_category {
  border-bottom: 1px solid #eceef4 !important;
}

#cookiescript_injected_fsd .cookiescript_vendor_name a,
#cookiescript_injected_fsd .cookiescript_fsd_cookies_table td:nth-child(2) {
  color: #00a89c !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_cookies::-webkit-scrollbar-thumb,
#cookiescript_injected_fsd .cookiescript_fsd_tabs_content::-webkit-scrollbar-thumb {
  background-color: #eceef4 !important;
}

#cookiescript_injected_fsd .cookiescript_fsd_cookies::-webkit-scrollbar-track,
#cookiescript_injected_fsd .cookiescript_fsd_tabs_content::-webkit-scrollbar-track {
  background-color: #f7f8fb !important;
}

#cookiescript_injected_fsd #cookiescript_save {
  background-color: white !important;
  border: none !important;
  color: #00a89c !important;
  font-family: 'RedHatText_SemiBold', Montserrat, sans-serif !important;
  transition: none !important;
}

#cookiescript_injected_fsd #cookiescript_reject {
  transition: none !important;
  border: none !important;
  background-color: #f7f8fb !important;
  color: #000 !important;
}

// switch toggle
#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd
  .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus, :active)
  .mdc-switch__handle::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__handle::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::before,
#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before,
#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::before,
#cookiescript_injected_fsd .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch:enabled .mdc-switch__track::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch:enabled:hover:not(:focus, :active) .mdc-switch__track::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
  background: #00a89c !important;
}

#cookiescript_injected_fsd .mdc-switch:enabled:active .mdc-switch__track::after {
  background: #00a89c !important;
}

// badge
#cookiescript_badge {
  display: none !important;
}
