:root {
  --accent-color: theme('colors.accent');
  --primary-color: theme('colors.primary');
  --gray-color: theme('colors.gray.DEFAULT');
  --gray-dark-color: theme('colors.gray.dark');
  --gray-ultralight: theme('colors.gray.ultralight');
  --dark-color: theme('colors.dark');
  --warning-color: theme('colors.warning');
  --bike-color: theme('colors.bike');
  --walk-color: theme('colors.walk');
  --car-color: theme('colors.car');
  --carpool-color: theme('colors.carpool');
  --transit-color: theme('colors.transit');
  --scooter-color: theme('colors.scooter');
  --internal-shuttle-color: theme('colors.internalShuttle');
  --transparent: theme('colors.transparent');
  --light-color: theme('colors.light.DEFAULT');

  // overwrite material css colors
  --mdc-outlined-text-field-outline-color: theme(colors.gray.medium);
  --mdc-outlined-text-field-hover-outline-color: theme(colors.primary);
  --mdc-filled-text-field-disabled-container-color: transparent;
  --mdc-filled-text-field-container-color: transparent;
  --mat-form-field-state-layer-color: transparent;
  --mdc-outlined-text-field-disabled-input-text-color: theme(colors.gray.dark);
}
