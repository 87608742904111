@use '@angular/material' as mat;

@include mat.core;

// create color palettes here: http://mcg.mbitson.com/#!?mcgpalette0=%23ff4a32
// primary, accent and warn is needed
$md-primary: (
  50: #e0f5f3,
  100: #b3e5e1,
  200: #80d4ce,
  300: #4dc2ba,
  400: #26b5ab,
  500: #00a89c,
  600: #00a094,
  700: #00978a,
  800: #008d80,
  900: #007d6e,
  A100: #aafff3,
  A200: #77ffec,
  A400: #44ffe5,
  A700: #2affe2,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$md-accent: (
  50: #e6e5ee,
  100: #c0bfd5,
  200: #9694ba,
  300: #6c699e,
  400: #4d4989,
  500: #2d2974,
  600: #28246c,
  700: #221f61,
  800: #1c1957,
  900: #110f44,
  A100: #817eff,
  A200: #4f4bff,
  A400: #1d18ff,
  A700: #0600fd,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #fff,
    400: #fff,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #fff,
    A400: #fff,
    A700: #fff,
  ),
);
$md-error: (
  50: #fee8ec,
  100: #fcc5cf,
  200: #fa9faf,
  300: #f7798e,
  400: #f65c76,
  500: #f43f5e,
  600: #f33956,
  700: #f1314c,
  800: #ef2942,
  900: #ec1b31,
  A100: #fff,
  A200: #ffecee,
  A400: #ffb9bf,
  A700: #ffa0a8,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

// assign created primary, accent and warn color palette
$mat-theme-primary: mat.m2-define-palette($md-primary);
$mat-theme-accent: mat.m2-define-palette($md-accent);
$mat-theme-warn: mat.m2-define-palette($md-error);

// set font for theme
$typography: mat.m2-define-typography-config(
  $font-family: 'RedHatText_Regular',
);

// create theme
$app-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $mat-theme-primary,
      accent: $mat-theme-accent,
      warn: $mat-theme-warn,
    ),
    typography: $typography,
    density: 0,
  )
);

// apply theme
@include mat.core-theme($app-theme);
@include mat.all-component-themes($app-theme);

// expose material colors as css variables
:root {
  --mat-warn-color: #{mat.m2-get-color-from-palette($mat-theme-warn, default)};
}
