.pac-container {
  @apply shadow-2xl rounded-xl border-none mt-2 font-redHatTextRegular;
}

.pac-item {
  @apply px-3.5 py-2 border-t border-t-gray-light;
}

.pac-logo::after {
  @apply mb-2 mr-2;
}

.pac-icon {
  @apply hidden;
}
