.ngx-toastr {
  border-radius: theme('borderRadius.xl') !important;
  background-size: 18px 18px !important;
  box-shadow: theme('boxShadow.2xl') !important;

  &.toast-success {
    @apply bg-success;
  }

  &.toast-error {
    @apply bg-danger;
  }

  .toast-progress {
    @apply opacity-20 rounded-full;
  }
}
