.shadow-card {
  @apply rounded-2xl shadow-lg overflow-hidden bg-light p-5;
}

.border-card {
  @apply border-gray-light border rounded-xl cursor-pointer p-3;

  &--dashed {
    @apply border-dashed;
  }
}

.content-card {
  @apply bg-light rounded-2xl w-full h-full p-12;
}
